import { forwardRef, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { useLoadScript } from '@react-google-maps/api'
import { gMapsLibraries } from 'utils/data'

import { Field as FormikField } from 'formik'
import ErrorMessage from 'components/field/errorMessage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Card from 'components/card'

import { FieldAttributes, FieldProps } from 'formik'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SupportedLanguages, ValueLabel } from 'types'
import { FocusEvent } from 'react'

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

const sizes = {
    small: 'text-xs py-2',
    normal: ''
}

interface GenericFieldProps extends FieldAttributes<any> {
    label?: string
    size?: 'small' | 'normal'
    rightLabel?: string
    rightElement?: React.ReactNode
    icon?: IconProp
    leftElement?: React.ReactNode
    selectValue?: string
    selectClassName?: string
    handleSelectValue?: Function
    selectOptions?: ValueLabel[]
    className?: string
    containerClassName?: string
    wrapperClassName?: string
    rightElementClassName?: string
    rightElementOnClick?: () => void
    exportInput?: (value: string) => void
    showCharacterCounter?: boolean
    disableFormik?: boolean
    autocomplete?: {
        data?: any[]
        onClick: (value: string, index: number) => void
    }
}

const DefaultField = forwardRef<HTMLInputElement, GenericFieldProps>(({label, exportInput, disableFormik, size = 'normal', min = 0, leftElement, rightLabel, autocomplete, rightElement, rightElementOnClick, icon, selectValue, selectClassName, handleSelectValue, selectOptions, className, containerClassName, wrapperClassName, rightElementClassName, showCharacterCounter, maxLength, hasError, ...props}, ref) => {
    const [closeAutocompleteCard, setCloseAutocompleteCard] = useState<boolean>(false)

    useEffect(() => {
        if(autocomplete && autocomplete.data) {
            const found = autocomplete.data.indexOf(props.value) > -1

            if(!found) {
                setCloseAutocompleteCard(false)
            }
        }
    }, [closeAutocompleteCard, props.value])

    useEffect(() => {
        if(exportInput){
            exportInput(props.value)
        }
    }, [props.value])

    return(
        <div className={containerClassName}>
           <div className="flex justify-between">
                {label && <label htmlFor={props.name} className="label">{label}</label>}
                {rightLabel &&<span className="text-sm text-gray-500">{rightLabel}</span>}
            </div>
            <div className={cn('relative', wrapperClassName)}>
                {selectOptions && (
                    <div className="absolute inset-y-0 top-1 left-0 flex items-center">
                        <select value={selectValue} onChange={e => handleSelectValue && handleSelectValue(e.target.value)} className={cn('form-select h-auto py-1 pl-3 pr-8 focus:outline-none focus:border-transparent border-transparent bg-transparent', selectClassName)}>
                            {selectOptions.map(option => (
                                <option value={option.value} key={option.value}>{option.label}</option>
                            ))}
                        </select>
                    </div>
                )}
                {(icon || leftElement) && (
                    <div className={cn('absolute inset-y-0 pl-3 flex items-center pointer-events-none', {'left-0': !selectOptions, 'left-12': selectOptions})}>
                        {icon && <FontAwesomeIcon icon={icon} className="h-4 fa-w-16 text-gray-200"/>}
                        {leftElement && leftElement}
                    </div>
                )}
                <input onWheel={e => props.type === 'number' ? e.currentTarget.blur() : undefined} {...props} ref={ref} min={min} maxLength={maxLength} value={props.value !== undefined ? props.value : ''} className={cn('appearance-none text-sm form-input border rounded-lg placeholder-gray-400 focus:outline-none transition w-full focus:ring', {[sizes[size]]: size}, {'pl-10': icon && !selectOptions}, {'pl-16': selectOptions && !icon}, {'pl-20': icon && selectOptions}, {'pr-12': rightElement}, {'border-gray-200 focus:border-primary focus:ring-primary-lighter': !hasError}, {'border-red-600 text-red-600 placeholder-red-300 focus:border-red-600 focus:ring-red-300 pr-10': hasError}, {'cursor-not-allowed bg-gray-50': props.disabled}, className)}/>
                {hasError && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <FontAwesomeIcon icon={faExclamationCircle} className="h-4 fa-w-16 text-red-600"/>
                    </div> 
                )}
                {rightElement && (
                    <span onClick={() => rightElementOnClick && rightElementOnClick()} className={cn('-ml-px absolute inline-flex items-center right-0.5 space-x-2 px-4 py-1.5 font-medium text-sm rounded-r-lg text-gray-700 border border-gray-100 bg-gray-100 focus:outline-none top-0.5', {'': size !== 'small', 'text-xs': size === 'small'}, rightElementClassName)}>
                        {rightElement}
                    </span>
                )}
                {showCharacterCounter && maxLength && <span className={cn('absolute top-3 text-xs text-gray-400', {'right-4': !hasError, 'right-9': hasError})}>{props.value?.length || 0}/{maxLength}</span>}
                {autocomplete && autocomplete.data && autocomplete.data.length > 0 && props.value?.length > 0 && autocomplete.data.filter(single => single.toLowerCase().includes(props.value.toLowerCase())).length > 0 && !closeAutocompleteCard && (
                    <Card className="absolute top-12 bg-white z-50" noPadding childrenClassName="divide-y divide-gray-300">
                        {autocomplete.data.map((single: string, index) => single.toLowerCase().includes(props.value.toLowerCase()) && (
                            <p className="text-sm p-2 hover:bg-gray-100 cursor-pointer" key={index} onClick={() => {setCloseAutocompleteCard(true); autocomplete.onClick(single, index)}}>{single}</p>
                        ))}
                    </Card>
                )}
            </div>
            {hasError && <ErrorMessage name={props.name}/>}
        </div>
    )
})

const Field = forwardRef<HTMLInputElement, GenericFieldProps>(({disableFormik, ...props}, ref) => {
    function checkIfValueHasUselessSpaces(value: string, name: string, setFieldValue: (field: string, value: any) => void) {
        if(value?.length > 1) {
            if(value.startsWith(' ')) {
                value = value.slice(1)
            }
            if(value.endsWith(' ')) {
                value = value.slice(0, -1)
            }
        }

        setFieldValue(name, value)
    }
    
    return disableFormik ? (
        <DefaultField {...props} ref={ref}/>
    ) : (
        <FormikField {...props}>
            {({field, meta: {error, touched}, form: { submitCount, setFieldValue }}: FieldProps<any>) => {
                const hasError = error && (touched || submitCount > 0)

                function onBlur(e: FocusEvent<any>) {
                    if(props.type !== 'number') {
                        checkIfValueHasUselessSpaces(field.value, field.name, setFieldValue)
                    }

                    return field.onBlur(e)
                }
                
                return(
                    <DefaultField {...props} {...field} onBlur={onBlur} hasError={hasError} ref={ref}/>
                )
            }}
        </FormikField>
    )
})

export default Field

interface MultilangFieldProps extends GenericFieldProps {
    translationName?: {
        path: string
        key: string
    } | string
}

export function MultilangField({translationName, name, ...props}: MultilangFieldProps) {
    const [selectedLanguage, setSelectedLanguage] = useState<SupportedLanguages>('it')
    const fieldName = selectedLanguage === 'it' ? name : typeof translationName === 'object' ? `${translationName.path}.otherLanguages.${selectedLanguage}.${translationName.key}` : translationName ? `otherLanguages.${selectedLanguage}.${translationName}` : `otherLanguages.${selectedLanguage}.${name}`

    return(
        <Field name={fieldName} selectValue={selectedLanguage} selectClassName="w-18" handleSelectValue={setSelectedLanguage} selectOptions={[{value: 'it', label: '🇮🇹'}, {value: 'en', label: '🇬🇧'}]} {...props}/>
    )
}

interface GoogleAutocompleteFieldProps extends GenericFieldProps {
    getAutocompleteData: (data: google.maps.places.PlaceResult) => void
    additionalFields?: string[]
}

export function GoogleAutocompleteField({getAutocompleteData, additionalFields = [], ...props}: GoogleAutocompleteFieldProps) {
    const input = useRef<HTMLInputElement>(null)
    const fields = ['formatted_address', 'place_id', 'types', ...additionalFields]
    const { isLoaded } = useLoadScript({googleMapsApiKey: process.env.NEXT_PUBLIC_GMAPS_API as string, id: 'google-maps-script', libraries: gMapsLibraries})
    let autocomplete: google.maps.places.Autocomplete | null = null

    useEffect(() => {
        if(input.current && isLoaded) {
            autocomplete = new window.google.maps.places.Autocomplete(input.current!)
            autocomplete.setFields(fields)
            autocomplete.addListener('place_changed', () => sendAutocompleteData())
        }
    }, [input, isLoaded])

    function sendAutocompleteData() {
        if(autocomplete) {
            const data = autocomplete.getPlace()
            getAutocompleteData(data)
        }
    }

    return(
        <>
            <Field {...props} ref={input} type="search" autoComplete="off" id="gmaps-input"/>
            <style jsx global>{`
                #gmaps-input::-webkit-search-cancel-button, #gmaps-input::-ms-clear {-webkit-appearance: none;}
            `}</style>
        </>
    )
}