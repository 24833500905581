import { useEffect, useState } from 'react'
import { retryPayment } from 'api/billing'
import { useBrand } from 'utils/hooks'
import { numberFormatter } from 'utils'
import { useTranslate } from 'utils/hooks'

import Alert from 'components/alert'
import Button from 'components/button'
import Modal, { ModalBody } from 'components/modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import CancelIcon from '@mui/icons-material/Cancel'
import PaymentIcon from '@mui/icons-material/Payment'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

export default function FailedPayment() {
    const { brand } = useBrand()
    const { t } = useTranslate()
    const [paymentRetryStatus, setPaymentRetryStatus] = useState<'loading' | 'done' | 'error' | ''>('')

    useEffect(() => {
        if(paymentRetryStatus === 'loading') {
            retryPayment(brand._id)
            .then(() => setPaymentRetryStatus('done'))
            .catch(() => setPaymentRetryStatus('error'))
        }
    }, [paymentRetryStatus])

    return brand._id !== (process.env.NEXT_PUBLIC_DEMO_ID as string || '') ? (
        <div className="px-4 mt-[70px] pb-4 -mb-[70px]">
            <Alert color="red" title={t("Pagamento fallito")} icon={<CancelIcon/>}>
                <p className="text-sm">{t('Non è stato possibile elaborare il pagamento della fattura mensile di')} <span className="font-medium">{numberFormatter(brand?.invoiceToPay!, undefined, true)}€</span>. <br/>{t('Per continuare ad usufruire dei serivzi Easylivery, ricordati di effettaure il pagamento al più presto.')}</p>
                <div className="pt-4">
                    <Button color="red" size="small" onClick={() => setPaymentRetryStatus('loading')}>{t('Riprova pagamento')}</Button>
                </div>
            </Alert>
            <Modal width={450} isOpen={paymentRetryStatus.length > 0} onRequestClose={() => setPaymentRetryStatus('')} shouldCloseOnEsc={paymentRetryStatus === 'done'} shouldCloseOnOverlayClick={paymentRetryStatus === 'done'}>
                <ModalBody className="flex flex-col items-center space-y-4">
                    {paymentRetryStatus === 'loading' ? (
                        <>
                            <PaymentIcon className="text-primary !text-8xl"/>
                            <h4 className="p-0 pb-4">{t('Nuovo tentativo di pagamento')} {numberFormatter(brand?.invoiceToPay!, undefined, true)}€</h4>
                            <FontAwesomeIcon className="animate-spin text-3xl" icon={faSpinnerThird}/>
                        </>
                    ) : paymentRetryStatus === 'error' ? (
                            <>
                                <CancelIcon className="text-red-600 !text-8xl"/>
                                <h4 className="p-0 pb-4">{t('Si è verificato un errore')}</h4>
                                <div className="flex space-y-4 sm:space-y-0 flex-col sm:flex-row sm:space-x-4">
                                    <Button onClick={() => setPaymentRetryStatus('')}>{t('Torna indietro')}</Button>
                                    <Button color="primary" onClick={() => setPaymentRetryStatus('loading')}>{t('Riprova pagamento')}</Button>
                                </div>
                            </>
                    ) : paymentRetryStatus === 'done' && (
                        <>
                            <CheckCircleIcon className="text-primary !text-8xl"/>
                            <h4 className="p-0 pb-4">{t('Pagamento effettuato con successo')}</h4>
                            <Button color="primary" onClick={() => setPaymentRetryStatus('')}>{t('Torna indietro')}</Button>
                        </>
                    )}
                </ModalBody>
            </Modal>
        </div>
    ) : null
}