import cn from 'classnames'

import Card from 'components/card'

type Props = {
    color: 'red' | 'yellow'
    icon?: React.ReactNode
    title: string
    description?: string
    children?: React.ReactNode
    className?: string
}

const colors = {
    red: '!bg-red-200 text-red-600',
    yellow: 'bg-yellow-200'
}

export default function Alert({color, icon, title, description, className, children}: Props) {
    return(
        <Card className={cn('border-none', {[colors[color]]: color}, className)} childrenClassName="flex">
            {icon && (
                <div className="flex-shrink-0">
                    {icon}
                </div>
            )}
                <div className={cn({'ml-3 w-full': icon})}>
                    <h6 className="pb-0 leading-6">{title}</h6>
                    {description && <p className="text-sm">{description}</p>}
                    {children}
                </div>
        </Card>
    )
}