import cn from 'classnames'
import { useBrand, useOperator } from 'utils/hooks'
import { useRouter } from 'next/router'
import { useContext, useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'

import Seo from 'components/seo'
import FailedPayment from './_partials/failedPayment'
import Header from './header'

import { HeaderComponents, MultipleHeaderComponents, WithChildren } from 'types'
import GlobalContext from 'context'
import { isAndroidOrIos } from 'utils'
import { getAdminToken } from 'api/authentication'

type Props = {
    seoTitle?: string
    inlineChildren?: React.ReactNode
    className?: string
    headerComponentsMobileClassName?: string
    childrenClassName?: string
    noSpacing?: boolean
    noMarginBottom?: boolean
    headerComponents?: HeaderComponents
    style?: React.CSSProperties
    backButtonUrl?: string
}

export default function Page({children, seoTitle, className, childrenClassName, inlineChildren, noSpacing, headerComponents, headerComponentsMobileClassName, noMarginBottom, style, backButtonUrl}: WithChildren<Props>) {
    const { authenticated, redirect, appUpdate } = useContext(GlobalContext)
    const operator = useOperator()
    const { pathname } = useRouter()
    const hasHeader = useMemo(() => ['/forgot-password', '/reset-password'].indexOf(pathname) > -1, [pathname])
    const isLoading = useMemo(() => authenticated === null || redirect !== 'done', [authenticated, redirect])
    const isMobile = useMediaQuery({ query: '(max-width: 958px)' })
    const { brand } = useBrand()
    const middleComponents = !!(headerComponents as MultipleHeaderComponents)?.middleComponents ? (headerComponents as MultipleHeaderComponents).middleComponents : (!(headerComponents as MultipleHeaderComponents)?.rightComponents && !(headerComponents as MultipleHeaderComponents)?.hasOwnProperty('rightComponents')) ? headerComponents as React.ReactNode : undefined
    const rightComponents = !!(headerComponents as MultipleHeaderComponents)?.rightComponents ? (headerComponents as MultipleHeaderComponents).rightComponents : undefined
    const hasAdminToken = getAdminToken()

    return (
        <>
            {seoTitle && <Seo title={seoTitle} />}
            {(hasHeader || authenticated || isLoading) && <Header middleComponents={middleComponents} rightComponents={rightComponents} backButtonUrl={backButtonUrl} />}
            {isMobile && (middleComponents || rightComponents) && (
                <div className={cn('bg-white mt-[54px] px-6 border-b flex', {'py-2': !middleComponents && rightComponents}, headerComponentsMobileClassName)}>
                    <div className="w-full overflow-x-auto">
                        {middleComponents}
                    </div>
                    {rightComponents && rightComponents}
                </div>
            )}
            {!operator && brand?.invoiceToPay !== undefined && brand.invoiceToPay > 0 && <FailedPayment />}
            <div className={cn({
                    'px-4 2sm:px-4': !noSpacing,
                    'mt-[47px]': operator && (hasHeader || authenticated) && !appUpdate.available && !hasAdminToken,
                    'mt-[75px]': operator && (hasHeader || authenticated) && ((appUpdate.available && !hasAdminToken) || (!appUpdate.available && hasAdminToken)),
                    'mt-[103px]': operator && (hasHeader || authenticated) && appUpdate.available && hasAdminToken,
                    'mt-[15px]': isMobile && headerComponents !== undefined && (hasHeader || authenticated) && !appUpdate.available && !hasAdminToken,
                    'mt-[43px]': isMobile && headerComponents !== undefined && (hasHeader || authenticated) && ((appUpdate.available && !hasAdminToken) || (!appUpdate.available && hasAdminToken)),
                    'mt-[71px]': isMobile && headerComponents !== undefined && (hasHeader || authenticated) && appUpdate.available && hasAdminToken,
                    'mb-[4.5rem]': authenticated && isAndroidOrIos && !noMarginBottom && (hasHeader || authenticated) && !appUpdate.available && !hasAdminToken,
                    'mb-[6.25rem]': authenticated && isAndroidOrIos && !noMarginBottom && (hasHeader || authenticated) && ((appUpdate.available && !hasAdminToken) || (!appUpdate.available && hasAdminToken)),
                    'mb-[8rem]': authenticated && isAndroidOrIos && !noMarginBottom && (hasHeader || authenticated) && appUpdate.available && hasAdminToken,
                    'mb-12': authenticated && !isAndroidOrIos && !noMarginBottom && (hasHeader || authenticated) && !appUpdate.available && !hasAdminToken,
                    'mb-[104px]': authenticated && !isAndroidOrIos && !noMarginBottom && (hasHeader || authenticated) && appUpdate.available && hasAdminToken,
                    'mt-[70px]': (!isMobile || (isMobile && !headerComponents)) && (hasHeader || authenticated) && !appUpdate.available && !hasAdminToken,
                    'mt-[98px]': (!isMobile || (isMobile && !headerComponents)) && (hasHeader || authenticated) && ((appUpdate.available && !hasAdminToken) || (!appUpdate.available && hasAdminToken)),
                    'mt-[126px]': (!isMobile || (isMobile && !headerComponents)) && (hasHeader || authenticated) && appUpdate.available && hasAdminToken,
                }, className)} style={style}>
                {inlineChildren && (
                    <div className={cn('flex justify-between items-center', childrenClassName)}>
                        {inlineChildren && inlineChildren}
                    </div>
                )}
                {children}
            </div>
        </>
    )
}