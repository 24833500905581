import { forwardRef } from 'react'
import cn from 'classnames'

import { WithChildren } from 'types'

export type CardComponentProps = {
    title?: string | React.ReactNode
    titleClassName?: string
    header?: React.ReactNode
    headerClassName?: string
    footer?: React.ReactNode
    footerClassName?: string
    className?: string
    childrenClassName?: string
    onClick?: Function
    noPadding?: boolean
    noBorder?: boolean
}

const Card = forwardRef<HTMLDivElement, WithChildren<CardComponentProps>>(({title, titleClassName, header, footer, children, onClick, noPadding, className, childrenClassName, headerClassName, footerClassName, noBorder}, ref) => (
    <div className={cn('rounded-lg w-full flex flex-col bg-white', {'border': !noBorder}, {'p-4': !header && !footer && !noPadding}, className)} onClick={() => onClick && onClick()} ref={ref}>
        {title && !header ? (
            <h4 className={cn('leading-4', {'pt-4 pl-4 pb-0': header || footer}, titleClassName)}>{title}</h4>
        ) : header && (
            <div className={headerClassName}>
                <h4 className="leading-4 pb-0">{title}</h4>
                {header}
            </div>
        )}
        <div className={cn({'p-4': (header || footer) && !noPadding}, {'pt-0': header}, childrenClassName)}>
            {children}
        </div>
        {footer && (
            <div className={cn('border-t px-4 py-2.5 bg-gray-50 rounded-b-lg mt-auto', footerClassName)}>
                {footer}
            </div>
        )}
    </div>
))

export default Card