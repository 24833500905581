import useSWR from 'swr'
import { numberFormatter } from 'utils'
import request from 'api/request'
import { getBrandAndShopAndAddons } from 'api/authentication'
import { addNotification } from 'utils'

import { PaginatedData, Invoice, CurrentCosts, Card, BrandFunctionsPromise, BankAccount } from 'types'

export function getInvoices(brandId: string, page?: number) {
    const params = page !== undefined ? `?page=${page}` : ''
    const { data, error, mutate } = useSWR<PaginatedData<Invoice[]>, { error: string }>(`/brand/${brandId}/invoices${params}`)

    return { invoices: data, isLoading: !data && !error, error, mutate }
}

export function getCurrentBillingCosts(brandId: string, shopsNumber: number, isNewClient?: boolean) {
    const { data, error, mutate } = useSWR<CurrentCosts>(`/brand/currentcosts/${brandId}`)
    const shopsPrice = data && data.additionalShopCost > 0 && shopsNumber > 0 ? data.additionalShopCost * (shopsNumber - 1) : 0
    const currentCosts = (data && !isNewClient) ? {
        baseFee: `${numberFormatter(data.baseFee, undefined, true)}€`,
        ordersPrice: `${numberFormatter((data.payedOrdersUnder300Count * data.exceedOrderCostUnder300) + (data.payedOrdersOver300Count * data.exceedOrderCostOver300) + (data.tableOrderCount * data.exceedOrderCostTable), undefined, true)}€`,
        shops: shopsPrice > 0 ? `${shopsPrice}€` : undefined,
        amount: `${numberFormatter(data.amount + shopsPrice!, undefined, true)}€`,
        number: data.deliveryOrderCount + data.expressOrderCount + data.tableOrderCount
    } : (data && isNewClient) ? {
        ordersPrice: `${numberFormatter((data.payedOrdersUnder300Count * data.exceedOrderCostUnder300) + (data.payedOrdersOver300Count * data.exceedOrderCostOver300) + (data.tableOrderCount * data.exceedOrderCostTable), undefined, true)}€`,
        number: data.deliveryOrderCount + data.expressOrderCount + data.tableOrderCount
    } : undefined

    return { currentCosts, isLoading: !data && !error, error, mutate }
}

export function retryPayment(brandId: string) {
    return new Promise<void>((resolve, reject) => {
        request('post', '/brand/retry-invoice', { brand: brandId })
            .then(() => resolve())
            .catch(() => reject())
    })
}

export function editCard(brandId: string, card: Card, updateBrandAndShops?: boolean) {
    return new Promise<BrandFunctionsPromise | void>((resolve, reject) => {
        request('post', '/brand/edit-card', { ...card, cardNumber: card.cardNumber!.replace(/ /g, ''), expireDate: card.expireDate!.replace(/\ /g, ''), brand: brandId })
            .then(() => {
                if (updateBrandAndShops) {
                    getBrandAndShopAndAddons()
                        .then(brandsAndShops => resolve(brandsAndShops))
                        .catch(() => {
                            addNotification('Si è verificato un errore, riprova', 'danger')
                            return reject()
                        })
                } else {
                    return resolve({ brands: [], shops: [] })
                }
            })
            .catch(() => {
                addNotification('Si è verificato un errore, riprova', 'danger')
                return reject()
            })
    })
}

export function editBankAccount(brandId: string, bankAccount: BankAccount, updateBrandAndShops?: boolean) {
    return new Promise<BrandFunctionsPromise | void>((resolve, reject) => {
        request('post', '/brand/edit-bank-account', { ...bankAccount, brand: brandId })
            .then(() => {
                if (updateBrandAndShops) {
                    getBrandAndShopAndAddons()
                        .then(brandsAndShops => resolve(brandsAndShops))
                        .catch(() => {
                            addNotification('Si è verificato un errore, riprova', 'danger')
                            return reject()
                        })
                } else {
                    return resolve({ brands: [], shops: [] })
                }
            })
            .catch(() => {
                addNotification('Si è verificato un errore, riprova', 'danger')
                return reject()
            })
    })
}

export function enableProduct(brand: string, products: string[]) {
    return new Promise<void>((resolve, reject) => {
        request('post', '/addon/enable-products', { brand, products })
            .then(() => resolve())
            .catch(() => reject())
    })
}

